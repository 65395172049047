import "../../src/globals.scss";
import Footer from "../components/Footer";
import React from "react";

function DeveloperContent() {
  return (
    <div>
      <div className="main-container">
        <div className="letter-container"></div>
        {/* ------------------------------- */}
        <div className="info-page-one" id="dev">
          <div className="intro big-top-margin medium-font reveal active fade-in">
            <div className="top-level">
              <span className="reveal active flip-one">W</span>
              <span className="reveal active end-name">ark Darling</span>
              <span className="reveal active fade-in-after-flip">
                eb Developer
              </span>
            </div>
          </div>
        </div>

        {/* ------------------------------- */}

        <div className="intro medium-font">
          <div className="top-level work reveal scroll-up">
            Recent
            <br />
            Work
          </div>
        </div>

        {/* ------------------------------- */}

        <div className="info-page-two">
        <div className="intro medium-font reveal scroll-up">
            <a
              href="https://www.teammateme.com"
              target="blank"
              rel="noreferrer"
              className="thumbnail-teammateme"
            ></a>
            <div className="pop-up-q3">Site Development</div>
          </div>
          <div className="intro medium-font reveal scroll-up">
            <a
              href="https://648b199588c7fd053359ca5e--quest-3.netlify.app/"
              target="blank"
              rel="noreferrer"
              className="thumbnail-q3"
            ></a>
            <div className="pop-up-q3">UI design and animation</div>
          </div>
          <div className="intro medium-font reveal scroll-up">
            <a
              href="https://mooncat.community/"
              target="blank"
              rel="noreferrer"
              className="thumbnail-ponderware"
            ></a>
            <div className="pop-up-ponderware">
              Site maintenance and features
            </div>
          </div>
          <div className="intro medium-font reveal scroll-up">
          </div>
          <div className="intro medium-font reveal scroll-up">
            <a
              href="https://e-commerce-demo-85a36.web.app/"
              target="blank"
              rel="noreferrer"
              className="thumbnail-ecommerce"
            ></a>
            <div className="pop-up-ecommerce">E-Commerce Demo</div>
          </div>
          <div className="intro medium-font reveal scroll-up top-level">
            <a
              href="https://github.com/itsmarkdarling"
              target="blank"
              rel="noreferrer"
              className="github-link"
            >GitHub with other coding examples</a>
          </div>
          <div className="intro medium-font reveal scroll-up">
            <div className="top-level">
              <a href="/resume" className="github-link">
                Resume
              </a>
              {/* <a href="/letter" className="cover-letter">
                Cover Letter
              </a> */}
            </div>
          </div>
          <div className="pad"></div>
          <Footer />
        </div>

        {/* ------------------------------- */}

        <div className="intro large-font reveal scroll-up">
          <p className="top-level"></p>
        </div>

        {/* ------------------------------- */}
      </div>
    </div>
  );
}

export default DeveloperContent;
