import "./globals.scss";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Developer from "./pages/Developer";
import Home from "./pages/Home";
import Resume from "./pages/Resume";
import Letter from "./pages/Letter";

import ResumePrint from "./pages/ResumePrint";

class App extends React.Component {

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/developer" exact element={<Developer />} />
          <Route path="/resume" exact element={<Resume />} />
          <Route path="/resumeprint" exact element={<ResumePrint />} />
          <Route path="/letter" exact element={<Letter />} />
          <Route path="/" exact element={<Home />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 400;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);

export default App;
