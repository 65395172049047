import "../../src/globals.scss";
import React from "react";

function Footer() {
  return (
    <div className="footer">
      <a
        className="fa-brands fa-twitter social"
        href="https://twitter.com/markdarlingmark"
        target="_blank"
        rel="noreferrer"
      ></a>
      <a
        className="fa-brands fa-instagram social"
        href="https://www.instagram.com/mark_darling/"
        target="_blank"
        rel="noreferrer"
      ></a>
      <a
        className="fa-brands fa-linkedin social"
        href="https://www.linkedin.com/in/darlingmark/"
        target="_blank"
        rel="noreferrer"
      ></a>
      <a
        className="fa-brands fa-git social"
        href="https://github.com/itsmarkdarling"
        target="_blank"
        rel="noreferrer"
      ></a>
    </div>
  );
}

export default Footer;
