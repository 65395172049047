import "../../src/globals.scss";
import React from "react";

function MusicFooter() {
  return (
    <div className="footer">
      <a
        className="fa-brands fa-twitter social"
        href="https://twitter.com/markdarlingmark"
        target="_blank"
        rel="noreferrer"
      ></a>
      <a
        className="fa-brands fa-instagram social"
        href="https://www.instagram.com/mark_darling/"
        target="_blank"
        rel="noreferrer"
      ></a>
      <a
        className="fa-brands fa-facebook social"
        href="https://www.facebook.com/mark.darling"
        target="_blank"
        rel="noreferrer"
      ></a>
      <a
        className="fa-brands fa-youtube social"
        href="https://www.youtube.com/channel/UCkCpFWlEBi0UQO_2aur5wFA"
        target="_blank"
        rel="noreferrer"
      ></a>
    </div>
  );
}

export default MusicFooter;
