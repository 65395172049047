import "../globals.scss";
import React from "react";
import Footer from "../components/Footer";

function Letter({ Component, pageProps }) {
  return (
    <div className="resume-body">
      <div className="name">
        <a href="/developer" className="name-link">
          Mark Darling
        </a>
      </div>
      <div className="contact-fade">
          <a
            className="resume-link"
            href="https://gitlab.com/itsmarkdarling"
            target="_blank"
            rel="noreferrer"
          >
            gitlab.com/itsmarkdarling
          </a>
          <a
            className="resume-link"
            href="mailto:itsmarkdarling@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            itsmarkdarling@gmail.com
          </a>
          <div className="phone">(720) 291-0106</div>
        </div>
      <div className="letter-summary">
        <p className="">
          When I began working as a developer for{" "}
          <a
            href="https://www.ponderware.com/"
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            Ponderware
          </a>{" "}
          one year ago, I had no idea what a blockchain was, what smart contracts were, or what an NFT was. Today, those are the basics. I am now a more competent and functional developer because of the challenges I rose to understand and the solutions I implemented. People thought it was very ambitious to switch careers from being a Registered Nurse to a Web Developer, but when confronted with the challenge of learning new languages, I thrive.
        </p>
        <p>
        My career change is not meant to downplay the satisfaction of helping a patient in need as a nurse, which I did enjoy. It is stated to highlight the shift into a creative and playful problem solving role as a developer, and the natural passion and engagement that I have found within it. Being a web developer allows my work to radiate excitement because I am in a place of joy, one of continued learning, growth, and creativity.
        </p>
        <p>
        When I build a web application, I am conscious of the passion and engagement that new ideas bring to users. I try my best to give users excitement in design, groundbreaking functionality, or animations that will stir up feelings of wonder. I am excited to further develop in other skills, languages, and frameworks that will allow me more freedom to implement outstanding applications.
        </p>
        <p>
        My goal is to work at an organization that prioritizes groundbreaking methods in their field. I currently create web applications with Next.js, React.js, Nuxt, Vue, JavaScript, HTML, CSS, SCSS, and Firebase. I wish to learn from those who push themselves to greater heights. If I sound like someone who would enhance your organization, please reach out. I look forward to hearing from you.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Letter;
