import "../globals.scss";
import React from "react";
import Loading from "../components/Loading";
import DeveloperContent from "../components/DeveloperContent";

class ErrorHandler extends React.Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(err) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <p className="error-msg">Loading failed. Please refresh the browser.</p>;
    }

    return this.props.children;
  }
}

function Developer() {
  return (
    <ErrorHandler>
      <React.Suspense fallback={<Loading />}>
        <DeveloperContent />
      </React.Suspense>
    </ErrorHandler>
  );
}

export default Developer;
