// import "../print.scss";
import React from "react";
import Footer from '../components/Footer';

function Resume() {
  return (
    <div className="resume-body">
        <div className="name">
          <a href="/developer" className="name-link">
            Mark Darling
          </a>
        </div>

        <div className="summary">
        Passionate and creative full stack web developer offering one year experience
          producing responsive decentralized applications using React, Vue,
          JavaScript, HTML, and CSS. I am currently interested in
          securing a role within an innovative organization in order to reach my full potential as a developer.
        </div>
        <div className="contact reveal fade-in">
          <a
            className="resume-link"
            href="https://gitlab.com/itsmarkdarling"
            target="_blank"
            rel="noreferrer"
          >
            gitlab.com/itsmarkdarling
          </a>
          <a
            className="resume-link"
            href="mailto:itsmarkdarling@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            itsmarkdarling@gmail.com
          </a>
          <div className="phone">(720) 291-0106</div>
        </div>
        <div className="content-container">
          <div>
            <div className="detail-section reveal scroll-up">
              <h3>Professional Experience</h3>
              <h4 className="big-title">Ponderware</h4>
              <p className="resume-title">Developer</p>
              <p className="resume-title">September 2021 - September 2022</p>
              <div className="subtext">
                <p>
                  Built responsive decentralized applications using React, Vue,
                  JavaScript, HTML, and CSS
                </p>
                <p>
                  Worked with marketing and dev teams to ensure successful
                  development, deployment, and maintenance of web3 applications
                </p>
                <p>
                  Assisted in organizing and preparing client feedback and
                  requirements
                </p>
                <p>
                  Created internal tooling for interacting directly with
                  arbitrary smart contracts
                </p>
                <p>
                  Created processes and workflows for routine maintenance and
                  content updates
                </p>
                <p>Participated in dev-team code-reviews and testing</p>
                <p>Modeled and rendered 3D images for promotional marketing</p>
                <p>Wrote documentation for existing and new software</p>
              </div>
            </div>
            <div className="detail-section">
              <div className="reveal scroll-up">
                <h3>Technical Skills</h3>
                <div className="icon-container">
                  <div className="icon">
                    <i className="fa-brands fa-react react"></i>
                    React
                  </div>
                  <div className="icon">
                    <i className="fa-brands fa-vuejs vue"></i>Vue
                  </div>
                  <div className="icon">
                    <i className="fa-brands fa-html5 html5"></i>HTML
                  </div>
                  <div className="icon">
                    <i className="fa-brands fa-css3-alt css3"></i>CSS
                  </div>
                  <div className="icon">
                    <i className="fa-brands fa-sass sass"></i>SCSS
                  </div>
                  <div className="icon">
                    <i className="fa-brands fa-js javascript"></i>JavaScript
                  </div>
                  <div className="icon">
                    <i className="fa-brands fa-git-alt git"></i>Git
                  </div>
                  <div className="icon">
                    <i className="fa-brands fa-docker docker"></i>
                    Docker
                  </div>
                  <div className="icon">
                    <i className="fa-brands fa-node nodejs"></i>
                    Node
                  </div>
                  <div className="icon">
                    <i className="fa-brands fa-npm npm"></i>
                    npm
                  </div>
                  <div className="icon">
                    <img
                      src="assets/img/solidity.png"
                      className="solidity"
                      alt="icon"
                    />
                    Solidity
                  </div>
                  <div className="icon">
                    <img
                      src="assets/img/hardhat.png"
                      className="solidity"
                      alt="icon"
                    />
                    Hardhat
                  </div>
                  <div className="icon">
                    <img
                      src="assets/img/sourcetree_logo.svg"
                      className="solidity"
                      alt="icon"
                    />
                    Sourcetree
                  </div>
                  <div className="icon">
                    <img
                      src="assets/img/jquery.svg"
                      className="solidity"
                      alt="icon"
                    />
                    jQuery
                  </div>
                  <div className="icon">
                    <img
                      src="assets/img/nextjs.png"
                      className="solidity"
                      alt="icon"
                    />
                    Next
                  </div>
                  <div className="icon">
                    <img
                      src="assets/img/nuxt.png"
                      className="solidity"
                      alt="icon"
                    />
                    Nuxt
                  </div>
                  <div className="icon">
                    <span className="ejs">{`<%=`} </span>
                    EJS
                  </div>
                  <div className="icon">
                    <img 
                    src="assets/img/mongodb.webp"
                    className="mongodb"
                    alt="icon"
                    ></img>
                    MongoDB
                  </div>
                  <div className="icon">
                    <img 
                    src="assets/img/sql.png"
                    className="sql"
                    alt="icon"
                    ></img>
                    SQL
                  </div>
                  <div className="icon">
                    <img 
                    src="assets/img/tableau.png"
                    className="tableau"
                    alt="icon"
                    ></img>
                    Tableau
                  </div>
                  <div className="icon">
                    <img
                      src="assets/img/photoshop.png"
                      className="solidity"
                      alt="icon"
                    />
                    Photoshop
                  </div>
                  <div className="icon">
                    <img
                      src="assets/img/illustrator.png"
                      className="solidity"
                      alt="icon"
                    />
                    Illustrator
                  </div>
                  <div className="icon">
                    <img
                      src="assets/img/blender.png"
                      className="solidity"
                      alt="icon"
                    />
                    Blender
                  </div>
                </div>
              </div>
            </div>
            <div className="detail-section reveal scroll-up">
              <h3>Independent Study</h3>
              <p className="big-title">Udemy</p>
              <p className="resume-title-small">March 2021 - September 2021</p>
              <p className="resume-title-small">
                The Complete 2021 Web Development Bootcamp
              </p>
              <div className="subtext udemy-container">
                <div>Front-End Web Development</div>
                <div>Intro to HTML</div>
                <div>Intermediate HTML</div>
                <div>Intro to CSS</div>
                <div>Intermediate CSS</div>
                <div>Intro to Bootstrap 4</div>
                <div>Intermediate Bootstrap</div>
                <div>Web Design</div>
                <div>Intro to JavaScript</div>
                <div>The DOM</div>
                <div>Create a Dice Game</div>
                <div>
                  Advanced JavaScript
                  <br /> and DOM Manipulation
                </div>
                <div>jQuery</div>
                <div>Build a Simon Game</div>
                <div>The Unix Command Line</div>
                <div>Backend Web Development</div>
                <div>Node.js</div>
                <div>Express.js with Node.js</div>
                <div>APIs</div>
                <div>Git, GitHub, and Version Control</div>
                <div>EJS</div>
                <div>Build a Blog Website</div>
                <div>Databases</div>
                <div>MongoDB</div>
                <div>Mongoose</div>
                <div>Deploying a Web Application</div>
                <div>Building a RESTful API from scratch</div>
                <div>Authentication and Security</div>
                <div>React.js</div>
                <div>Web3 Decentralized App Development</div>
              </div>
            </div>
          </div>
          <div className="detail-section-small reveal scroll-up">
            <h4>Previous Work Experience</h4>
            <div className="subtext-small">
              <p className="resume-title-small">Registered Nurse</p>
              <p>
                Home Health RN - Collaborated with Doctors in order to manage
                and implement care for patients
              </p>
              <p>RN Oncology - St. Joseph Hospital - Denver</p>
              <p>Pediatric Home Health RN</p>
              <p>2016 - 2020</p>
            </div>
            <h4>Education</h4>
            <div className="subtext-small">
              <p className="resume-title-small">
                Denver College of Nursing - Bachelor of Science (Nursing)
              </p>
              <p>2015</p>
              <p className="resume-title-small">
                Virginia Tech - Bachelor of Science (Biological Sciences)
              </p>
              <p>2012</p>
            </div>
          </div>
        </div>
    </div>
  );
}

export default Resume;
